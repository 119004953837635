<template>
	<v-row>
		<v-col>
			<v-card class="card-shadow-primary">
				<v-card-text class="text-center">
					<span>Total Solicitudes</span><br />
					<span class="text-h3">{{ statistics.totalReveided }}</span>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col>
			<v-card class="card-shadow-primary">
				<v-card-text class="text-center">
					<span>Solicitudes de Atencion Abiertas</span><br />
					<span class="text-h3">{{
						totalRequestForAttentionOpen
					}}</span>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col>
			<v-card class="card-shadow-primary">
				<v-card-text class="text-center">
					<span>Solicitudes Cerradas</span><br />
					<span class="text-h3">{{ statistics.totalClosed }}</span>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col>
			<v-card class="card-shadow-primary">
				<v-card-text class="text-center">
					<span>Rol de Regantes - Deuda</span><br />
					<span class="text-h3"><v-btn color="info" @click="downloadDebtIrrigators">Descargar
							XLS</v-btn></span>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
	name: 'count-event-card',

	computed: {
		...mapState({
			statistics: state => state.eventBookControl.statistics,
			user: state => state.userControl.user,
			type: state => state.userControl.type,
		}),

		totalRequestForAttentionOpen() {
			return this.statistics.totalRequestForAttention;
		},
		totalOpen() {
			return this.statistics.totalOpen;
		},
	},

	mounted() {
		this.loadstatistics({
			type: this.type,
			association_user: this.user.id,
		});
	},

	methods: {
		...mapActions('irrigatorControl', ['downloadDebtIrrigators']),
		...mapActions('eventBookControl', ['loadstatistics']),
	},
};
</script>
